<template>
    <div class="container">
        <Login ref="login"></Login>
        <div class="header">
            <div class="menus">
                <img src="../../assets/logo.png" class="login pointer" @click="$router.push(`/index`)" />
                <div>
                    <el-input placeholder="请输入关键字" v-model.trim="keys" style="width: 380px">
                        <el-button class="search_btn" slot="append" icon="el-icon-search"
                            @click="goSearchPage"></el-button>
                    </el-input>
                </div>
                <div class="r-user">
                    <div class="phone">
                        <div><img src="../../assets/电话-icon.png" /></div>
                        <div>
                            <div class="text">全国统一订票服务热线</div>
                            <div class="number">{{configs.find(item=>item.keyName=="phone").value}}</div>
                            <div class="discribe">官网订票·安全可靠</div>
                        </div>
                    </div>

                    <div class="login_text" @click="login(0)" v-show="_.isEmpty(userInfo)">
                        <img src="../../assets/登录-icon.png" class="m_r_5" />
                        请登录
                    </div>
                    <!-- <div @click="login(1)" class="pointer" v-show="_.isEmpty(userInfo)">
                        注册
                    </div> -->
                    <el-dropdown class="order_text" v-show="!_.isEmpty(userInfo)">
                        <span>
                            <i class="el-icon-s-order"></i>
                            我的订单
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div class="flex flex_row" style="border-bottom: 1px #e7ecf4 solid">
                                    <div class="m_r_5" style="margin-top: 8px">
                                        <img src="../../assets/头像.png" />
                                    </div>
                                    <div>
                                        <div style="color: #2c2c2c" class="fs_16 fw_blod">
                                            尊敬的会员
                                        </div>
                                        <div class="fs_13" style="color: #bebebe; margin-top: -12px">
                                            {{ formatPhone(userInfo.userName) }}
                                        </div>
                                    </div>
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item @click.native="$router.push('/family')">
                                <div class="p_lr_10">
                                    <i class="el-icon-bank-card"></i>
                                    出行人管理
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item @click.native="$router.push('/personal')">
                                <div class="p_lr_10">
                                    <i class="el-icon-tickets"></i>
                                    我的订单
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div class="p_lr_10">
                                    <i class="el-icon-headset"></i>
                                    在线客服
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div class="p_lr_10" @click="logOut">
                                    <i class="el-icon-switch-button"></i>
                                    退出登录
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <el-menu :default-active="activeIndex" background-color="#1974d0" text-color="#fff" active-text-color="#fff"
            class="el-menu" mode="horizontal" :router="true" @select="handleSelect">
            <!-- <el-menu-item index="/index">首页</el-menu-item>
      <el-menu-item
        :index="`/presale/${item.routeId}`"
        v-for="item in navRoute">
        {{ item.orgainCityName }}到{{ item.destinationCityName }}游
      </el-menu-item>
      <el-menu-item index="/presale">船票预定</el-menu-item>
      <el-submenu
        :index="`/product/${item.id}`"
        v-for="(item, index) in shipGroupDataList"
        v-if="index < 4"
        :key="index">
        <template slot="title">{{ item.shipGroupName }}</template>
        <el-menu-item
          :index="`/product/${item2.id}`"
          v-for="(item2, index2) in item.shipList"
          :key="index2">
          {{ item2.shipName }}
        </el-menu-item>
      </el-submenu>
      <el-submenu
        v-if="shipGroupDataList.length == 5"
        :index="`/product/${shipGroupDataList[4].id}`"
        key="5">
        <template slot="title">
          {{ shipGroupDataList[4].shipGroupName }}
        </template>
        <el-menu-item
          :index="`/product/${item2.id}`"
          v-for="(item2, index2) in shipGroupDataList[4].shipList"
          :key="index2">
          {{ item2.shipName }}
        </el-menu-item>
      </el-submenu> -->
            <el-menu-item :index="item.index" v-for="(item, index) in menus[0]" v-show="item.items.length == 0">
                {{ item.name }}
            </el-menu-item>
            <el-submenu :index="item.index" v-for="(item, index) in menus[0]" v-show="item.items.length > 0">
                <template slot="title">
                    {{ item.name }}
                </template>
                <el-menu-item :index="`/product/${item2.id}`" v-for="(item2, index2) in item.items" :key="index2">
                    {{ item2.shipName }}
                </el-menu-item>
            </el-submenu>
            <el-menu-item index="" v-show="menus[1].length > 0" @click="showMore" @mouseenter.native="showMore"
                @mouseout.native="hideMore">
                更多
            </el-menu-item>
        </el-menu>
        <div class="moreLine" v-show="isShow" @mouseleave="hideMore()" @mouseenter="showMore()">
            <el-menu :default-active="activeIndex" background-color="#fff" text-color="#333333"
                active-text-color="#1467c8" mode="horizontal" :router="true" @select="handleSelect">
                <el-menu-item :index="item.index" v-for="(item, index) in menus[1]" v-show="item.items.length == 0">
                    {{ item.name }}
                </el-menu-item>
                <el-submenu :index="item.index" v-for="(item, index) in menus[1]" v-show="item.items.length > 0"
                    :key="index">
                    <template slot="title">{{ item.name }}</template>
                    <el-menu-item @click.native="hideMore()" @mouseenter.native="showMore()"
                        @mouseleave.native="hideMore()" :index="`/product/${item2.id}`"
                        v-for="(item2, index2) in item.items" :key="index2">
                        {{ item2.shipName }}
                    </el-menu-item>
                </el-submenu>
            </el-menu>
        </div>
    </div>
</template>
<script>
    import Login from "@/components/LoginDialog";
    import {
        getShipGroupDataList,
        getNavRoute
    } from "@/api/home/index";
    import {
        mapGetters
    } from "vuex";
    export default {
        name: "HomeHeader",
        data: () => {
            return {
                timer: "",
                isShow: false,
                shipGroupDataList: [],
                activeIndex: "/",
                navRoute: "",
                keys: "", // 搜索关键字
                menus: [
                    [],
                    []
                ], //导航菜单项目
            };
        },
        async created() {
            let lists = [{
                name: "首页",
                index: "/index",
                items: []
            }, {
                name: "船票预定",
                index: "/presale",
                items: []
            }];
            //获取航线
            await getNavRoute().then((res) => {
                this.navRoute = res.data;
                res.data.map((item) => {
                    lists.push({
                        name: item.orgainCityName + "到" + item
                            .destinationCityName, // + "游" 删除游
                        index: "/presale/" + item.routeId,
                        items: [],
                    });
                });
            });
            //获取游船列表
            await getShipGroupDataList().then((response) => {
                this.shipGroupDataList = response.data;

                // lists.push();
                response.data.map((item) => {
                    lists.push({
                        name: item.shipGroupName,
                        index: "/presale/" + item.id,
                        items: item.shipList,
                    });
                });
            });
            //计算一行最多显示多少个菜单
            let len = 0;
            lists.map((item) => {
                len += item.name.length * 16 + 40;
                //第一行
                if (len <= 1150) {
                    this.menus[0].push(item);
                } else {
                    //更多项目
                    this.menus[1].push(item);
                }
            });
        },
        components: {
            Login,
        },
        computed: {
            ...mapGetters(["userInfo", "configs"]),
        },
        watch: {
            $route: {
                handler(newVal, oldVal) {
                    this.activeIndex = newVal.path;
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {
            hideMore() {
                this.timer = setTimeout(() => {
                    this.isShow = false;
                }, 200);
            },
            showMore() {
                clearTimeout(this.timer);
                this.isShow = true;
            },
            //手机号打码
            formatPhone(value) {
                if (!value) return "";
                let str = value.substring(0, 11);
                str = str.toString().replace(/^(\d{3})(\d{4})(\d{4})/g, "$1****$3");
                return str;
            },
            login(type) {
                this.$refs["login"].open(type);
                //this.$router.push("/login");
            },
            logOut() {
                this.$confirm("是否退出登录?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        this.$store.dispatch("LogOut");
                        this.$router.push("/index");
                    })
                    .catch(() => {});
            },

            handleSelect(e) {},

            //  去邮轮搜索页
            goSearchPage() {
                // startaddr，目的地endaddr，日期date，游轮系列shiptype，关键字keys
                if (this.keys == "") {
                    this.$message.warning("请输入关键字进行邮轮查询");
                    return;
                }
                var temdate = this.moment(new Date()).format("YYYY-MM-DD");
                this.$router.push(`/search?type=1&date=${temdate}&keys=${this.keys}`);
            },
        },
    };
</script>

<style scoped lang="scss">
    ::v-deep .el-menu-item {
        font-size: 16px;
    }

    ::v-deep .el-submenu.is-active .el-submenu__title {
        border-bottom-color: #f6943e !important;
    }

    ::v-deep .el-menu-item.is-active {
        background-color: #1467c8 !important;
        border-bottom: none !important;
        display: flex;
        justify-content: center;
    }

    ::v-deep .el-submenu__title {
        font-size: 16px;
    }

    ::v-deep .el-submenu__title i {
        color: #fff;
    }

    ::v-deep .el-menu-item.is-active::after {
        content: "";
        position: absolute;
        bottom: 2px;

        border: 2px #f6943e solid;
        width: 20px;
    }

    .moreLine {
        background: #fff;

        ::v-deep .el-menu-item.is-active {
            background-color: #fff !important;
        }

        ::v-deep .el-submenu__title i {
            color: #000 !important;
        }

        ::v-deep .el-submenu__title:hover {
            background-color: #fff !important;
            color: #1467c8 !important;
        }
    }

    .container {
        .search_btn {
            background-color: #1974d0;
            color: #fff;
        }

        .el-menu {
            border: 0px;
        }

        background-color: #1974d0;

        .header {
            padding: 20px 0;
            background-color: #fff;

            .menus {
                width: 1300px;
                font-size: 14px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .login {
                    width: 340px;
                }
            }

            .phone {
                display: flex;

                img {
                    padding-right: 10px;
                }

                .text {
                    color: #424242;
                }

                .number {
                    font-size: 24px;
                    font-weight: bold;
                    color: #424242;
                    margin-top: -2px;
                }

                .discribe {
                    margin-top: -2px;
                    color: #f69742;
                    font-size: 12px;
                    font-weight: 400;
                    letter-spacing: 0.3em;
                }
            }

            .r-user {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;

                .phone {
                    margin-right: 40px;
                    align-items: center;
                }

                .login_text {
                    margin-right: 25px;
                }
            }

            .login_text {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90px;
                height: 34px;
                background: #ecf4fb;
                cursor: pointer;
                color: #2c2c2c;
                border-radius: 17px;
            }

            .order_text {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 126px;
                height: 34px;
                color: #2c2c2c;
                background: #ecf4fb;
                border-radius: 17px;
                cursor: pointer;
            }
        }

        .el-menu {
            width: 1300px;
            margin: 0 auto;
        }
    }
</style>