<template>
  <div v-if="options != null">
    <template
      v-for="(item, index) in options.filter((el) =>
        values.includes(el.value)
      )">
      <template v-if="values.includes(item.value)">
        <template
          v-if="item.raw.listClass == 'default' || item.raw.listClass == ''">
          <template v-if="myTag">
            <span v-if="myTag == 'slash'">
              {{ item.label }}
              <span
                v-show="
                  values.length > 1 &&
                  index !==
                    options.filter((el) => values.includes(el.value)).length - 1
                ">
                /
              </span>
            </span>
            <span v-if="myTag == 'phrases'">
              {{ item.label }}
              <span
                v-show="
                  values.length > 1 &&
                  index !==
                    options.filter((el) => values.includes(el.value)).length - 1
                ">
                、
              </span>
            </span>
          </template>
          <template v-else>
            <el-tag
              size="small"
              v-if="tagType"
              :key="item.value"
              :index="index"
              :class="item.raw.cssClass">
              {{
                item.label.length > 7
                  ? item.label.substring(0, 7) + '...'
                  : item.label
              }}
            </el-tag>
            <span
              v-else
              :key="item.value"
              :index="index"
              :class="item.raw.cssClass">
              {{ item.label }}
            </span>
          </template>
        </template>
        <el-tag
          v-else
          :disable-transitions="true"
          :key="item.value"
          :index="index"
          :type="item.raw.listClass == 'primary' ? '' : item.raw.listClass"
          :class="item.raw.cssClass">
          {{ item.label }}
        </el-tag>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DictTag',
  props: {
    myTag: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    value: [Number, String, Array],
    tagType: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    values() {
      if (this.value !== null && typeof this.value !== 'undefined') {
        return Array.isArray(this.value) ? this.value : [String(this.value)];
      } else {
        return [];
      }
    },
  },
};
</script>
<style scoped>
.el-tag {
  margin-right: 10px;
}
</style>
