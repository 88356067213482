import axios from 'axios';
import { Message } from 'element-ui';
import { saveAs } from 'file-saver';
import { getToken } from '@/utils/auth';
import errorCode from '@/utils/errorCode';
import { blobValidate, tansParams } from '@/utils/tools';
import { Loading } from 'element-ui';
const baseURL = process.env.VUE_APP_BASE_API;
let downloadLoadingInstance;
export default {
  file(name, api, pramas) {
    var url = baseURL + api + '?' + tansParams(pramas);
    downloadLoadingInstance = Loading.service({
      text: '正在下载数据，请稍候',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    });
    axios({
      method: 'get',
      url: url,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('user')).user.token,
      },
    })
      .then(async (res) => {
        const blob = new Blob([res.data], {
          // 下载的文件格式自己在这边更改type的值就好了
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        this.saveAs(blob, name);
        downloadLoadingInstance.close();
      })
      .catch((r) => {
        console.error(r);
        Message.error('下载文件出现错误，请联系管理员！');
        downloadLoadingInstance.close();
      });
  },
  resource(resource) {
    var url =
      baseURL +
      '/common/download/resource?resource=' +
      encodeURIComponent(resource);
    axios({
      method: 'get',
      url: url,
      responseType: 'blob',
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then(async (res) => {
      const isLogin = await blobValidate(res.data);
      if (isLogin) {
        const blob = new Blob([res.data]);
        this.saveAs(blob, decodeURIComponent(res.headers['download-filename']));
      } else {
        this.printErrMsg(res.data);
      }
    });
  },
  zip(url, name) {
    var url = baseURL + url;
    axios({
      method: 'get',
      url: url,
      responseType: 'blob',
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then(async (res) => {
      const isLogin = await blobValidate(res.data);
      if (isLogin) {
        const blob = new Blob([res.data], { type: 'application/zip' });
        this.saveAs(blob, name);
      } else {
        this.printErrMsg(res.data);
      }
    });
  },
  saveAs(text, name, opts) {
    saveAs(text, name, opts);
  },
  async printErrMsg(data) {
    const resText = await data.text();
    const rspObj = JSON.parse(resText);
    const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default'];
    Message.error(errMsg);
  },
};
