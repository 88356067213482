import request from "@/utils/request";

// 查询邮轮班次房型项目列表
export function getSchedulingRoomItemList(query) {
  return request({
    url: "/biz/schedulingRoomItem/anon/getList",
    method: "get",
    params: query,
  });
}

// 通过班次ID查询游轮详情
export function getSchedulingRoomDetail(query) {
  return request({
    url: "/biz/schedulingRoom/anon/getDetail/" + query,
    method: "get",
  });
}

//  提交订单
export function submitOrder(query) {
  return request({
    url: "/biz/order/anon/submitOrder",
    method: "post",
    data: query,
  });
}

// 通过订单ID查询详情
export function getOrderDetailIntro(query) {
  return request({
    url: "/biz/order/anon/getDetail/" + query,
    method: "get",
  });
}

// 通过订单微信支付二维码
export function getWxPayCode(query) {
  return request({
    url: "/biz/order/anon/getWxPayCode/" + query,
    method: "get",
  });
}

//  通过订单支付宝支付二维码
export function getAliPayCode(query) {
  return request({
    url: "/biz/order/anon/getAliPayCode/" + query,
    method: "get",
  });
}

//  查询订单状态
export function getOrderStatus(query) {
  return request({
    url: "/biz/order/anon/getOrderStatus/" + query,
    method: "get",
    isLoading: false,
  });
}

//通过用户名手机号查询订单
export function getOrderPageList(query) {
  return request({
    url: "/biz/order/site/getOrderPageList",
    params: query,
    method: "get",
  });
}

//获取我的订单信息
export function getMyOrderList(query) {
  return request({
    url: "/biz/order/getMyOrderList",
    params: query,
    method: "get",
  });
}
//新增出行人
export function addOrderUser(query) {
  return request({
    url: "/biz/orderUser/anon/insert",
    data: query,
    method: "post",
  });
}
//编辑出行人
export function editOrderUser(query) {
  return request({
    url: "/biz/orderUser/anon/update",
    data: query,
    method: "put",
  });
}
//删除出行人
export function delOrderUser(id) {
  return request({
    url: "/biz/orderUser/anon/" + id,
    method: "delete",
  });
}
